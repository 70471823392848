import { Button, FormControl, Grid, Input, Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import axios from 'axios';
import React, { useState } from 'react';
import { useRecoilState } from 'recoil';
import { setUserSession } from '../../Utils/Common';
import { bedrijfState, tokenState } from '../../Utils/Recoil/Atoms';
import ReactGA from 'react-ga';
import CallModal from './Modal/CallModal'

function Login(props) {
  const username = useFormInput('');
  const password = useFormInput('');
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  // eslint-disable-next-line
  const [token, setToken] = useRecoilState(tokenState); //Global state
    // eslint-disable-next-line
  const [bedrijf, setBedrijf] = useRecoilState(bedrijfState)

  // handle button click of login form
  const onKeyDown = async(e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      e.stopPropagation();
      handleLogin();
    }
  }

  const handleLogin = async() => {
    setError(null);
    setLoading(true);

    try {
      const response = await axios.post('https://backend.blackcoffeeandsupplies.shop/auth/local', { 
        identifier: username.value, 
        password: password.value })
 
        if(response?.status === 200){
          setLoading(false);
          setUserSession(response?.data?.jwt, response.data.user);
          setToken(response?.data?.jwt);
          setBedrijf(response?.data?.user?.companies[0]?._id);
          

          ReactGA.event({
            category: 'User Action',
            action: 'Login',
            label: 'login door: '+response?.data?.user?.companies[0]?.bedrijfsnaam+ ' ('+response?.data?.user?.username+')'
          });

          props.history.push('/');
        }
    } catch (error) {
        // if (error.response) {
        //   // The request was made and the server responded with a status code
        //   // that falls out of the range of 2xx
        //   // console.log(error.response.data);
        //   // console.log(error.response.status);
        //   // console.log(error.response.headers);
        // } else if (error.request) {
        //     // The request was made but no response was received
        //     // `error.request` is an instance of XMLHttpRequest in the 
        //     // browser and an instance of
        //     // http.ClientRequest in node.js
        //     console.log(error.request);
        // } else {
        //     // Something happened in setting up the request that triggered an Error
        //     console.log('Error', error.message);
        // }
        // console.log(error.config);
      
        setLoading(false);
        setError("Het is niet gelukt je in te loggen, probeer het opnieuw");
        return error
    }
  }
 
  return (
    <>
      <Grid container direction="column" justifyContent="center" alignItems="center">
          <Typography variant="h1" gutterBottom style={{marginTop: 20}}>Inloggen</Typography>
          {error && <><Alert style={{margin: 10}} severity="error">{error}</Alert></>}
          <FormControl style={{width: 300}}>
            <Typography variant="body1" >Mailadres</Typography>
            <Input type="text" {...username} autoComplete="new-password" />
          </FormControl>
          <FormControl style={{ marginTop: 10, width: 300 }}>
          <Typography variant="body1" >Wachtwoord</Typography>
            <Input type="password" {...password} autoComplete="new-password" onKeyDown={onKeyDown} />
          </FormControl>
          <Button style={{ marginTop: 10, width: 300 }} onClick={handleLogin} disabled={loading} >{loading ? 'aan het inloggen...' : 'Inloggen'}</Button>
      </Grid>

      <Grid container direction="row" justifyContent="center" alignItems="center">
        <Grid item>
          <CallModal />
        </Grid>
      </Grid>

    </>
  );
}
 
const useFormInput = initialValue => {
  const [value, setValue] = useState(initialValue);
 
  const handleChange = e => {
    setValue(e.target.value);
  }
  return {
    value,
    onChange: handleChange
  }
}
 
export default Login;