import { Button, FormControl, Grid, Input, Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import axios from 'axios';
import ReactGA from 'react-ga';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom'
import { useRecoilState } from 'recoil';
// import { setUserSession } from '../../Utils/Common';
import { tokenState } from '../../Utils/Recoil/Atoms';

async function getCompanieName (id, setBedrijfsnaam){

  const {data: { bedrijfsnaam }} = await axios.get(`https://backend.blackcoffeeandsupplies.shop/companies/${id}`)

  setBedrijfsnaam(bedrijfsnaam);
}

function Register(props) {
  

  const gegevens = useParams();
  const naam = useFormInput('');
  const username = useFormInput('');
  const password = useFormInput('');
  const [error, setError] = useState(null);
  const [bedrijfsnaam, setBedrijfsnaam] = useState('');
  const [succes, setSucces] = useState(null);
  const [loading, setLoading] = useState(false);
  // eslint-disable-next-line
  const [token, setToken] = useRecoilState(tokenState); //Global state

  useEffect(() => {
    getCompanieName(gegevens.bedrijf, setBedrijfsnaam);
  },[gegevens.bedrijf])

  // handle button click of login form
  const onKeyDown = async(e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      e.stopPropagation();
      handleLogin();
    }
  }
  const handleLogin = async() => {
    setError(null);
    setLoading(true);

    try {
      const response = await axios.post('https://backend.blackcoffeeandsupplies.shop/auth/local/register', { 
        username: naam.value,
        email: username.value, 
        password: password.value,
        companies: [gegevens.bedrijf]
      })
 
        if(response?.status === 200){
          setLoading(false);
          console.log(response);
          setSucces(`Een account voor ${response?.data?.user?.username} is aangemaakt, je krijgt zo een bevestigingsmail op: ${response?.data?.user?.email}.`)

          ReactGA.event({
            category: 'User Action',
            action: 'Register',
            label: 'Registratie voor: '+response?.data?.user?.companies[0]?.bedrijfsnaam+ 'met gebruikersnaam: '+response?.data?.user?.username
          });

        }
    } catch (error) {
        // if (error.response) {
        //   // The request was made and the server responded with a status code
        //   // that falls out of the range of 2xx
        //   // console.log(error.response.data);
        //   // console.log(error.response.status);
        //   // console.log(error.response.headers);
        // } else if (error.request) {
        //     // The request was made but no response was received
        //     // `error.request` is an instance of XMLHttpRequest in the 
        //     // browser and an instance of
        //     // http.ClientRequest in node.js
        //     console.log(error.request);
        // } else {
        //     // Something happened in setting up the request that triggered an Error
        //     console.log('Error', error.message);
        // }
        // console.log(error.config);
      
        setLoading(false);
        setError("Het is niet gelukt je te registeren, probeer het opnieuw");
        return error
    }
  }
 
  return (
    <Grid container direction="column" justifyContent="center" alignItems="center">
        <Typography variant="h1" gutterBottom style={{marginTop: 20, textAlign: 'center'}}>Registeren voor <br />{bedrijfsnaam}</Typography>
        {error && <><Alert style={{margin: 10}} severity="error">{error}</Alert></>}
        {succes && <><Alert style={{margin: 10}} severity="success">{succes}</Alert></>}
        <FormControl style={{width: 300, marginTop: 15}}>
          <Typography variant="body1" >Voor - en Achternaam</Typography>
          <Input type="text" {...naam} autoComplete="new-password" />
        </FormControl>
        <FormControl style={{width: 300}}>
          <Typography variant="body1" >Mailadres</Typography>
          <Input type="text" {...username} autoComplete="new-password" />
        </FormControl>
        <FormControl style={{ marginTop: 10, width: 300 }}>
        <Typography variant="body1" >Wachtwoord</Typography>
          <Input type="password" {...password} autoComplete="new-password" onKeyDown={onKeyDown} />
        </FormControl>
        <Button style={{ marginTop: 10, width: 300 }} onClick={handleLogin} disabled={loading} >{loading ? 'bezig met account aanmaken...' : 'Registeren'}</Button>
    </Grid>
  );
}
 
const useFormInput = initialValue => {
  const [value, setValue] = useState(initialValue);
 
  const handleChange = e => {
    setValue(e.target.value);
  }
  return {
    value,
    onChange: handleChange
  }
}
 
export default Register;