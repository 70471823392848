import axios from "axios";
import ReactGA from 'react-ga';

export async function PlaceOrder(evt, order, bedrijf, klant, datum, opmerking, token, setOrderStatus, setLoading, setCartItems){
    evt.preventDefault();

    try {
        setLoading(true);
        setOrderStatus(`Een ogenblik geduld aub, je order wordt verwerkt... `);
        const response = await axios.post('https://backend.blackcoffeeandsupplies.shop/orders', { 
            companie: bedrijf,
            opmerking: opmerking,
            gewensteLeverdatum: datum.toLocaleDateString(),
            gebruiker: klant?._id,
            data: order
        },{
            headers: {
                'Authorization': 'Bearer '+token
            }
        })

        order.map((data) => {
            
            return(
                ReactGA.plugin.execute('ec', 'addProduct', {
                    id: data.itemcode,
                    name: data.omschrijving,
                    variant: data.gekozenEenheid,
                    quantity: data?.qty,
                    category: data?.categorie?.omschrijving
                })
            )
            
        });

        ReactGA.plugin.execute('ec', 'setAction', 'purchase', {
            affiliation: "Black Coffee and Supplies Order Systeem",
            id: response
          });

        ReactGA.ga('send','event','Ecommerce','Bestelling','Afgerond', {nonInteraction: true});
        ReactGA.plugin.execute('ec', 'clear');
        setOrderStatus(`Je order is aangemaakt met ordernummer: ${response}.`);
        setCartItems([]);
        setLoading(false);
      } catch (error) {
        console.log(error)
      }
}