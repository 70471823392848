import { Container, Grid, MenuItem, TextField, Typography, Select} from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import axios from 'axios';
import { setupCache } from 'axios-cache-adapter';
import { useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { getUser } from '../../Utils/Common';
import { bedrijfState, tokenState } from '../../Utils/Recoil/Atoms';
import Artikel from './Artikel/Artikel';

const cache = setupCache({
    maxAge: 15 * 60 * 1000
  })

const api = axios.create({
adapter: cache.adapter
})

function Dashboard({eenheid,onAdd,handleChange}) {
    const token = useRecoilValue(tokenState); //Global state
    const user = getUser();
    const [artikelData, setArtikelData] = useState();
    const [Loading, setLoading] = useState(false);
    const [search, setSearch] = useState('')
    const [bedrijf, setBedrijf] = useRecoilState(bedrijfState)

    async function handleBedrijfChange (e) {
        const value = e.target.value;
        setBedrijf(value);
    }

    useEffect(() => {
        setLoading(true)
        const fetchArtikelen = async () => {
            try {
                const {data} = await api(`https://backend.blackcoffeeandsupplies.shop/companies/${bedrijf}`, {
                    headers: {
                        'Authorization': 'Bearer '+token
                    }
                },{
                    adapter: cache.adapter
                })
                setArtikelData(await data);
                setLoading(false);
                return
            } catch (error) {
                console.log(error);
            }
        }
        if(token.length > 20){
            fetchArtikelen();
        }
    // eslint-disable-next-line    
    },[token, bedrijf])
    
    if(Loading === true){
        return(
            <Container>
                <Alert severity="info" style={{marginTop: 20}}>
                    <AlertTitle>Artikelen worden geladen</AlertTitle>
                    Even geduld aub, uw artikelen worden geladen...
                </Alert>
            </Container>
        )
    }

    if(artikelData?.geblokkeerd === true){
        return(
            <Container>
                <Alert severity="error" style={{marginTop: 20}}>
                    <AlertTitle>Bestellen is niet mogelijk</AlertTitle>
                    Het is voor u momenteel niet mogelijk om te bestellen, neem voor meer informatie contact op met onze administratie via  <strong>033 47 00 979</strong>
                </Alert>
            </Container>
        )
    }

    const filteredItems = search.length === 0 ? artikelData?.articles : 
    artikelData?.articles?.filter(data => data.omschrijving.toLowerCase().includes(search.toLowerCase()))

    return (
        <Container>
        <Typography variant="h1" gutterBottom>Uw artikelen</Typography>
        <Grid container spacing={4} >
            <Grid item xs={12} md={8}>
                <TextField 
                    placeholder="Zoeken..."
                    fullWidth={true} 
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    style={{marginBottom: 10}}
                />
            </Grid>
            <Grid item xs={12} md={4}>
                    <Select
                    labelId="bedrijf-selector"
                    id="bedrijf-selector"
                    value={bedrijf}
                    onChange={handleBedrijfChange}
                    autoWidth={false}
                    fullWidth
                    disabled={user?.companies.length > 1 ? false : true}
                    >
                    
                    {user?.companies?.map((bedrijven) =>{
                        return(
                            <MenuItem key={bedrijven?._id} value={bedrijven?._id}>{bedrijven?.bedrijfsnaam}</MenuItem>
                        )
                    })}
                    </Select>
            </Grid>
        </Grid>
        
            <Grid container spacing={2}>
                {filteredItems?.sort(function(a,b) {
                    if(a.categorie.toLowerCase() < b.categorie.toLowerCase()) return -1;
                    if(a.categorie.toLowerCase() > b.categorie.toLowerCase()) return 1;
                    return 0;
                })?.map(data => {
                    return(
                        <Artikel key={data._id} data={data} onAdd={onAdd} handleChange={handleChange} eenheid={eenheid} />   
                    )
                })}
            </Grid>
        </Container>
    )
}

export default Dashboard
