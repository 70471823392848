import React from 'react';
import { useMediaQuery,  } from '@material-ui/core';

import Desktop from './Desktop/Desktop';
import Mobile from './Mobile/Mobile';

 const Navbar = ({items})=>{
    const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
    
    if (isMobile === false) {
    return(
        <Desktop item={items} />
    )
    }else{
        return(
            <Mobile item={items} />
        )
    }
}

export default Navbar;