import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { RecoilRoot } from 'recoil';
import { createTheme, CssBaseline, ThemeProvider } from '@material-ui/core';
import ReactGA from 'react-ga';
import { getUser } from './Utils/Common';

const user = getUser();

const theme = createTheme({
  typography: {
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Poppins',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    h1: {
      fontSize: '2rem',
      marginTop: 10,
      marginBottom: 10,
      '@media (min-width:600px)': {
        fontSize: '2.5rem',
        marginTop: 10,
        marginBottom: 10,
      }
    },
    h2: {
      fontSize: '2rem',
      '@media (min-width:600px)': {
        fontSize: '2.5rem',
      }
    },
    h3: {
      fontSize: '2rem',
      '@media (min-width:600px)': {
        fontSize: '2.5rem',
      }
    },
    h4: {
      fontSize: '2rem',
      '@media (min-width:600px)': {
        fontSize: '2.5rem',
      }
    },
    h5: {
      fontSize: '1.5rem',
      fontWeight: 300,
      color: 'darkGrey',
      '@media (min-width:600px)': {
        fontSize: '1.7rem',
        fontWeight: 300,
        color: 'darkGrey'
      }
    },
    caption: {
      fontStyle: 'italic',
    }

  },
});

var trackingID = user?.username

ReactGA.initialize('UA-160832939-7',{
  debug: false,
  gaOptions: {
    userId: trackingID
  }
});
ReactGA.plugin.require('ec');

ReactDOM.render(
  // <React.StrictMode>
    <RecoilRoot>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <App />
      </ThemeProvider>
    </RecoilRoot>,
  // </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
