import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { Button, Grid, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

export default function CallModal() {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
        <Typography onClick={handleOpen} variant="subtitle2" style={{color: 'lightGrey', cursor: 'pointer'}}>Nog geen account?</Typography>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <Grid container direction="row" justifyContent="space-between">
            <Grid item>
                <Typography variant="h5" id="transition-modal-title" gutterBottom>Ik heb nog geen account?</Typography>
            </Grid>
            <Grid item>
                <Button onClick={handleClose}>X</Button>
            </Grid>
            </Grid>
            
            <Typography variant="body1" id="transition-modal-description">
                Als je voor jullie bedrijf gebruik wil maken van onze bestelomgeving moeten jullie je eerst registeren.<br /><br />
                Om te registeren kunnen jullie contact opnemen met <a href="mailto:administratie@blackcoffeeandsupplies.nl">administratie@blackcoffeeandsupplies.nl</a>.<br />
                In de mail is het belangrijk aan te geven voor welk bedrijf je je wilt registeren. Wij zullen je dan een link sturen <br />
                welke je kan gebruiken voor het registreren van 1 of meer gebruikers. Deel deze link <b>NIET</b> met mensen die niet <br />
                voor jullie mogen bestellen.<br /><br />

                Na het registreren ontvang je op het opgegeven mailadres een activatie link, nadat je hier op klikt kom je <br />
                terug op deze pagina en kan je inloggen. Je kan nu beginnen met bestellen.<br /><br />

                Mocht je er toch niet helemaal uitkomen, neem gerust contact met ons op.<br /><br />

                

            </Typography>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}