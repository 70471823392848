import React from 'react';
import { Link } from 'react-router-dom'
import { AppBar, Button, Chip, Grid, Toolbar  } from '@material-ui/core';
import { useRecoilValue } from 'recoil';
import ShoppingCartOutlinedIcon from '@material-ui/icons/ShoppingCartOutlined';
import { tokenState} from '../../../Utils/Recoil/Atoms';
import ReactGA from 'react-ga';
import CallModal from './CallModal';

import { removeUserSession } from '../../../Utils/Common';

function Desktop(items) {

    const token = useRecoilValue(tokenState); //Global state

    const handleLogout = () => {
        removeUserSession();
        ReactGA.event({
            category: 'User Action',
            action: 'Logout',
            label: 'Gebruiker uitgelogd'
          });
        window.location.reload();
      }

    return (
        <AppBar color={'inherit'} position="static" style={{height: '100px',background: '#FFFFFF'}}>
            <Toolbar style={{flex: 1}}>
                <Grid container justifyContent="space-between" direction="row" alignItems="center">
                    <Grid item>
                        <img src="https://cdn.shopify.com/s/files/1/0561/4665/7485/files/Zwart_2_180x.png?v=1617973767" alt="Logo" height="80"/>
                    </Grid>
                    <Grid item>
                        <Grid container justifyContent="flex-end" direction="row" alignItems="center">
                            <Grid item >
                                <Button component={Link} to={'/'}>Berichten</Button>
                            </Grid>
                            {token && <Grid item >
                                  <Button component={Link} to={'/dashboard'}>Assortiment</Button>
                            </Grid>}
                            {token && <Grid item >
                                {token && <Button component={Link} to={'/lopendeorders'}>Actieve Orders</Button>}
                            </Grid>}
                            {token && <Grid item >
                                <Button component={Link} to={'/cart'}><Chip variant="outlined" size="small" color={items.item ? "secondary" : "default" } label={'Huidige bestelling ( '+items.item+' )'} icon={<ShoppingCartOutlinedIcon />} style={{padding: 3, cursor: 'pointer'}} /></Button>
                            </Grid>}
                            <Grid item >
                                {token ? <Button color="inherit" onClick={handleLogout}>Uitloggen</Button> : <Button color="inherit" component={Link} to={'/login'}>Inloggen</Button>}
                            </Grid>
                            {token && <Grid item >
                                <CallModal />
                            </Grid>}
                        </Grid>
                    </Grid>
                </Grid>
            </Toolbar>
        </AppBar>
    )
}

export default Desktop
