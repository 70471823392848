import React, { useEffect, useState } from 'react'
import ReactMarkdown from "react-markdown"
import { Container, Typography, Paper  } from '@material-ui/core'
import Moment from 'moment';
import 'moment/locale/nl';
import axios from 'axios';
import { Alert, AlertTitle } from '@material-ui/lab';

Moment.locale('nl');

function Home() {
    const [messages, setMessages] = useState([]);
    const [Loading, setLoading] = useState(false);

    useEffect(() => {
        let unmounted = false;
        setLoading(true);

        async function fetchData() {
            try {
                const {data} = await axios.get(`https://backend.blackcoffeeandsupplies.shop/updates?_limit=10&_sort=createdAt:desc`);
                if (!unmounted) {
                    setMessages(data);
                    setLoading(false);
                  }
            } catch (error) {
                console.log(error)
            }
        }
        fetchData();
        return () => { unmounted = true };  
    },[])

    if(Loading){
        return (
            <Container>
                <Alert severity="info" style={{marginTop: 20}}>
                    <AlertTitle>Berichten worden geladen</AlertTitle>
                    Even geduld aub, de berichten worden geladen...
                </Alert>
            </Container>
        );
    }

    return (
        <div>
            <Container >
                <Typography variant="h1" gutterBottom color={'secondary'}> Belangrijke mededelingen:</Typography>      
                {messages&&  
                messages.map((data) =>{
                    var datum = data.createdAt;
                    var update = data.updatedAt
                    return(
                        <Paper key={data._id} style={{marginBottom: '10px'}}>
                            <div style={{padding: '2rem'}}>
                            <Typography variant="h4" gutterBottom >{data.title}</Typography>
                            <Typography variant="caption">Geplaatst: {Moment(datum).fromNow()} & Laatste Update: {Moment(update).fromNow()}</Typography>
                            <ReactMarkdown children={data.message} linkTarget={'_blank'} />
                            </div>
                        </Paper>
                    )
                })}
            </Container>
        </div>
    )
}

export default Home
