import React from 'react';
import { Link } from 'react-router-dom'
import { AppBar, Toolbar  } from '@material-ui/core';
import { useRecoilValue } from 'recoil';
import { tokenState} from '../../../Utils/Recoil/Atoms';
import ReactGA from 'react-ga';

import { alpha, makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Badge from '@material-ui/core/Badge';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MailIcon from '@material-ui/icons/Mail';
import ShoppingCartOutlinedIcon from '@material-ui/icons/ShoppingCartOutlined';
import ListAltOutlinedIcon from '@material-ui/icons/ListAltOutlined';
import MoreIcon from '@material-ui/icons/MoreVert';
import RestoreOutlinedIcon from '@material-ui/icons/RestoreOutlined';
import CallModal from './CallModal';


import { removeUserSession } from '../../../Utils/Common';

const useStyles = makeStyles((theme) => ({
    grow: {
      flexGrow: 1,
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      display: 'none',
      [theme.breakpoints.up('sm')]: {
        display: 'block',
      },
    },
    search: {
      position: 'relative',
      borderRadius: theme.shape.borderRadius,
      backgroundColor: alpha(theme.palette.common.white, 0.15),
      '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
      },
      marginRight: theme.spacing(2),
      marginLeft: 0,
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(3),
        width: 'auto',
      },
    },
    searchIcon: {
      padding: theme.spacing(0, 2),
      height: '100%',
      position: 'absolute',
      pointerEvents: 'none',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    inputRoot: {
      color: 'inherit',
    },
    inputInput: {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
      transition: theme.transitions.create('width'),
      width: '100%',
      [theme.breakpoints.up('md')]: {
        width: '20ch',
      },
    },
    sectionDesktop: {
      display: 'none',
      [theme.breakpoints.up('md')]: {
        display: 'flex',
      },
    },
    sectionMobile: {
      display: 'flex',
      [theme.breakpoints.up('md')]: {
        display: 'none',
      },
    },
  }));

function Mobile(items) {

    const classes = useStyles();
    const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  
    const handleMobileMenuClose = () => {
      setMobileMoreAnchorEl(null);
    };

    const handleMobileMenuOpen = (event) => {
      setMobileMoreAnchorEl(event.currentTarget);
    };

    const token = useRecoilValue(tokenState); //Global state

    const handleLogout = () => {
        removeUserSession();
        ReactGA.event({
            category: 'User Action',
            action: 'Logout',
            label: 'Gebruiker uitgelogd'
          });
        window.location.reload();
      }

    
      
    const menuId = 'primary-search-account-menu';

    const mobileMenuId = 'primary-search-account-menu-mobile';
    const renderMobileMenu = ( 
        <>
          <Menu
              anchorEl={mobileMoreAnchorEl}
              anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
              id={mobileMenuId}
              keepMounted
              transformOrigin={{ vertical: 'top', horizontal: 'right' }}
              open={isMobileMenuOpen}
              onClose={handleMobileMenuClose}
          >
              <MenuItem component={Link} to="/">
              <IconButton aria-label="show news" color="inherit">
                  <Badge color="secondary">
                  <MailIcon />
                  </Badge>
              </IconButton>
              <p>Berichten</p>
              </MenuItem>
              <MenuItem component={Link} to="/Dashboard">
              <IconButton aria-label="show articles" color="inherit">
                  <Badge color="secondary">
                  <ListAltOutlinedIcon />
                  </Badge>
              </IconButton>
              <p>Assortiment</p>
              </MenuItem>
              <MenuItem component={Link} to="/lopendeorders">
              <IconButton aria-label="show articles" color="inherit">
                  <Badge color="secondary">
                  <RestoreOutlinedIcon />
                  </Badge>
              </IconButton>
              <p>Actieve orders</p>
              </MenuItem>
              <MenuItem component={Link} to="/Cart">
              <IconButton aria-label="show 11 new notifications" color="inherit">
                  <Badge badgeContent={items.item} color="secondary">
                  <ShoppingCartOutlinedIcon />
                  </Badge>
              </IconButton>
              <p>Huidige bestelling</p>
              </MenuItem>
              <MenuItem onClick={handleLogout}>
              <IconButton
                  aria-label="account of current user"
                  aria-controls="primary-search-account-menu"
                  aria-haspopup="true"
                  color="inherit"
              >
                  <AccountCircle />
              </IconButton>
              <p>Uitloggen</p>
              </MenuItem>
          </Menu>
        </>
    );


    return (
        <div className={classes.grow}>
            <AppBar color={'primary'} position="static">
                <Toolbar>
                <IconButton
                    edge="start"
                    className={classes.menuButton}
                    color="inherit"
                    aria-label="open drawer"
                >
                    <img src="https://backend.blackcoffeeandsupplies.shop/uploads/Diap_b235912b44.png" alt="logo" width="50px" />
                </IconButton>
                <Typography className={classes.title} variant="h6" noWrap>
                    Black Coffee and Supplies
                </Typography>
                {/* <div className={classes.search}>
                    <div className={classes.searchIcon}>
                    <SearchIcon />
                    </div>
                    <InputBase
                    placeholder="Zoeken..."
                    classes={{
                        root: classes.inputRoot,
                        input: classes.inputInput,
                    }}
                    inputProps={{ 'aria-label': 'search' }}
                    />
                </div> */}
                <div className={classes.grow} />
                <div className={classes.sectionDesktop}>
                    <IconButton aria-label="show 4 new mails" color="inherit">
                    <Badge badgeContent={4} color="secondary">
                        <MailIcon />
                    </Badge>
                    </IconButton>
                    <IconButton aria-label="show 17 new notifications" color="inherit">
                    <Badge badgeContent={17} color="secondary">
                        <ListAltOutlinedIcon />
                    </Badge>
                    </IconButton>
                    <IconButton
                    edge="end"
                    aria-label="account of current user"
                    aria-controls={menuId}
                    aria-haspopup="true"
                    color="inherit"
                    >
                    <AccountCircle />
                    </IconButton>
                </div>
                <Typography variant="body1">
                  Klik hier om te beginnen -&gt;
                </Typography>
                {token ?
                <div className={classes.sectionMobile}>
                    <IconButton
                    aria-label="show more"
                    aria-controls={mobileMenuId}
                    aria-haspopup="true"
                    onClick={handleMobileMenuOpen}
                    color="inherit"
                    >
                    <MoreIcon />
                    </IconButton>
                    <IconButton color="inherit">
                      <CallModal />
                    </IconButton>
                    
                </div> : 
                <div>
                    <IconButton
                    aria-label="Inloggen"
                    color="inherit"
                    component={Link} 
                    to="/login"
                    >
                        <AccountCircle />
                    </IconButton>
                </div>
                }
                </Toolbar>
            </AppBar>
            {renderMobileMenu}
        </div>
        
        
        
        
        
        
        
        
        
        // <AppBar color={'inherit'} position="static" style={{height: '100px',background: '#FFFFFF'}}>
        //     <Toolbar style={{flexGrow: 1}}>
        //         <Grid justifyContent="space-between" container>
        //             <Grid>
        //                 <img src="https://cdn.shopify.com/s/files/1/0561/4665/7485/files/Zwart_2_180x.png?v=1617973767" alt="Logo" height="80"/>
        //             </Grid>
        //             <Grid item >
        //                 <Button component={Link} to={'/'}>News</Button>
        //                 {token && <Button component={Link} to={'/dashboard'}>Artikelen</Button>} 
        //                 {token && <Button component={Link} to={'/cart'}><Chip variant="outlined" size="small" color={items.item ? "secondary" : "default" } label={'Huidige bestelling ( '+items.item+' )'} icon={<ShoppingCartOutlinedIcon />} style={{padding: 3, cursor: 'pointer'}} /></Button>}
        //                 {token ? <Button color="inherit" onClick={handleLogout}>Uitloggen</Button> : <Button color="inherit" component={Link} to={'/login'}>Inloggen</Button>}
        //             </Grid>
        //         </Grid>
        //     </Toolbar>
        // </AppBar>
    )
}

export default Mobile
