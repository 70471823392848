import { useEffect, useState } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import jwt_decode from "jwt-decode";

import './App.css';

import Navbar from './components/Navbar/Navbar';
import Dashboard from './components/Dashboard/Dashboard';
import Cart from './components/Cart/Cart';
import Login from './components/Login/Login';
import Register from './components/Register/Register';
import Home from './components/Home/Home';
import BarcodeScanner from './components/BarcodeScanner/BarcodeScanner';
import { useRecoilState } from 'recoil';

import PrivateRoute from './Utils/PrivateRoute';
import PublicRoute from './Utils/PublicRoute';
// eslint-disable-next-line
import { getToken, removeUserSession, setUserSession } from './Utils/Common';
import { tokenState, cartState} from './Utils/Recoil/Atoms';
import ReactGA from 'react-ga';
import withTracker from './Utils/useGaTracker';
import LopendeOrders from './components/LopendeOrders/LopendeOrders';

function App() {

  if (window.performance) {
  // Gets the number of milliseconds since page load
  // (and rounds the result since the value must be an integer).
    var timeSincePageLoad = Math.round(performance.now());

  // Sends the timing hit to Google Analytics.
    ReactGA.ga('send', 'timing', 'JS Dependencies', 'load', timeSincePageLoad);
}

  //State setting
  // eslint-disable-next-line
  const [token, setToken] = useRecoilState(tokenState); //Global state
  const [cartItems, setCartItems] = useRecoilState(cartState);
  // eslint-disable-next-line
  const [authLoading, setAuthLoading] = useState(false);
    
  useEffect(() => {
    setToken(getToken());
  // eslint-disable-next-line  
  }, [])

  useEffect(() => {
    const token = getToken();
    if (!token) {
      return;
    }

    try {
      setAuthLoading(true);
      const { exp } = jwt_decode(token);
      if (Date.now() >= exp * 1000) {
        console.log('Vervallen');
        removeUserSession();
        window.location.reload();
        return
      }
      setAuthLoading(false);
    } catch (err) {
      return false;
    }
  }, []);
 
  if (authLoading) {
    return <div className="content">We checken je inloggegevens.</div>
  }
      
  //Add products to cart
  const onAdd = (e, product, keuze) => {        
      e.preventDefault();
      const exist = cartItems.find((x) => x.id === product._id);
      if (exist) {

        if(keuze.eenheid === '') {
          console.log('maak uw keuze voor eenheid')
          return
        }
        if(keuze.aantal <1){
          console.log('Kies een aantal boven 0')
          return
        }
        if(isNaN(keuze.aantal)){
          console.log('Geen nummer')
          return
        }

        setCartItems(
          cartItems.map((x) =>
            x.id === product.id ? { ...exist, qty: Number(exist.qty) + Number(keuze.aantal) } : x
          )
        );

        ReactGA.plugin.execute('ec', 'addProduct', {
          id: product._id,
          name: product.omschrijving,
          variant: keuze.eenheid,
          quantity: 1,
          category: product?.categorie?.omschrijving
        });
        ReactGA.plugin.execute('ec', 'setAction', 'add')
        ReactGA.ga('send', 'event', 'User Action', 'Order', 'Toegevoegd aan bestelling');

      } else {
          if(keuze.eenheid === '') {
            console.log('maak uw keuze voor eenheid')
            return
          }
          if(keuze.aantal <1){
            console.log('Kies een aantal boven 0')
            return
          }
          if(isNaN(keuze.aantal)){
            console.log('Geen nummer')
            return
          }
          setCartItems([...cartItems, { ...product, qty: Number(keuze.aantal), gekozenEenheid: keuze.eenheid }]);
          
          // const testItems = JSON.stringify({id: product._id, qty: Number(keuze.aantal), gekozenEenheid: keuze.eenheid});
          
          // let current = localStorage?.getItem('cart');

          // if(current){
          //   current = JSON.stringify([...current, testItems]);
          //   console.log(current)
          //   localStorage.setItem("cart", current);
          // }else{
          //   localStorage.setItem("cart", [testItems]);
          // }

          ReactGA.plugin.execute('ec', 'addProduct', {
            id: product._id,
            name: product.omschrijving,
            variant: keuze.eenheid,
            quantity: Number(keuze.aantal),
            category: product?.categorie?.omschrijving
          });
          ReactGA.plugin.execute('ec', 'setAction', 'add')
          ReactGA.ga('send', 'event', 'User Action', 'Order', 'Toegevoegd aan bestelling');

          
      }
    };
   
  // Remove products from cart
  const onRemove = (e, product) => {
    e.preventDefault();
    
    const exist = cartItems.find((x) => x.id === product._id);
    
    if (exist.qty === 1) {
      setCartItems(cartItems.filter((x) => x.id !== product._id));
      ReactGA.plugin.execute('ec', 'addProduct', {
        id: product._id,
        name: product.omschrijving,
      });
      ReactGA.plugin.execute('ec', 'setAction', 'remove')
      ReactGA.ga('send', 'event', 'User Action', 'Order', 'Verwijderd van bestelling');
    } else {
      setCartItems(
        cartItems.map((x) =>
          x.id === product.id ? { ...exist, qty: Number(exist.qty) - 1 } : x
        )
      );
      ReactGA.plugin.execute('ec', 'addProduct', {
        id: product._id,
        name: product.omschrijving,
      });
      ReactGA.plugin.execute('ec', 'setAction', 'remove')
      ReactGA.ga('send', 'event', 'User Action', 'Order', 'Verwijderd van bestelling');
    }
  };

  return (
    <>
      <BrowserRouter>
      <Navbar items={cartItems.length} />
        <Switch>
          <Route exact path="/" component={withTracker(Home)} />          
          <PrivateRoute path="/dashboard" component={withTracker(() => 
            <Dashboard 
              onAdd={onAdd}
            />)}
          />
          <PrivateRoute path="/cart" component={withTracker(() => 
            <Cart 
              onAdd={onAdd} 
              onRemove={onRemove} 
 
            />)} 
          />
          <PrivateRoute path="/lopendeorders" component={withTracker(LopendeOrders)} />
          <PrivateRoute path="/barcode" component={withTracker(() => 
            <BarcodeScanner 
              onAdd={onAdd}
            />)}
          />
          <PublicRoute path="/login" component={withTracker(Login)} />
          <PublicRoute path="/registeruser/:bedrijf" component={withTracker(Register)} />

        </Switch>
      </BrowserRouter>
    </>
  );
}

export default App;

