import { ClickAwayListener, Button, Card, CardActions, CardContent, CardMedia, Fade, Grid, MenuItem, Select, TextField, Tooltip, Typography } from '@material-ui/core'
import React, { useState } from 'react'
import { useMediaQuery,  } from '@material-ui/core';


function Artikel({data, onAdd}) {
    const isMobile = useMediaQuery((theme) => theme.breakpoints.down("xs"));

    // eslint-disable-next-line
    var productImage = new Image();
    productImage.src = `data:image/png;base64,${data.image}`;

    const [artikel, setArtikel] = useState({
        id: data._id,
        eenheid: '',
        aantal: ''
      });

    function handleChange(evt) {
        const value = evt.target.value;
        setArtikel({
          ...artikel,
          [evt.target.name]: value
        });
      }
    
    const [open, setOpen] = useState(false);
    const handleTooltipClose = () => {
        setOpen(false);
      };
    
      const handleTooltipOpen = () => {
        setOpen(true);
      };

    return (
        <Grid item xs={12} sm={6} md={4} lg={3}>
            <Card variant="outlined" style={{position: 'relative'}}>
                <CardContent>
                    <ClickAwayListener onClickAway={handleTooltipClose}>
                        <Tooltip
                            PopperProps={{
                            disablePortal: true,
                            }}
                            onClose={handleTooltipClose}
                            open={open}
                            disableFocusListener
                            disableHoverListener
                            disableTouchListener
                            placement="bottom"
                            arrow TransitionComponent={Fade} 
                            TransitionProps={{ timeout: 400 }}
                            title={data.omschrijving}
                            style={{cursor: "pointer"}}
                        >
                            <Typography noWrap={true} onClick={handleTooltipOpen} variant="h6">{data.omschrijving}</Typography>
                        </Tooltip>
                    </ClickAwayListener>
                    <CardMedia
                        style={{height: isMobile ? '371px' : '262px'}}
                        image= {productImage.src.length > 50 ? productImage.src :  process.env.PUBLIC_URL + '/images/noimg.jpg'}//{productImage.src}
                        title={data.omschrijving}
                    />
                    <Typography variant="caption" color="textSecondary" gutterBottom >
                        {data.itemcode}
                    </Typography>
                    <Typography variant="caption" color={data.voorraad < 11 ? 'secondary' : 'primary'} gutterBottom>
                        <br />{data.voorraad > 0 ? `Voorraad: `+ Math.round(data.voorraad) +' '+ data.basiseenheid.toLowerCase() : data.inBestelling < 1 && 'Niet op voorraad, wordt voor je besteld'}
                        {data.inBestelling > 1 && (data.voorraad > 1 ? ', ' : 'Niet op voorraad, ') + data.inBestelling +' '+ data.basiseenheid.toLowerCase() + ' in bestelling'}
                    </Typography>
                    {data.opmerkingNL &&
                    <Typography variant="caption" color="secondary" style={{position: 'absolute',
                        top: '45px',
                        left: '0px',
                        backgroundColor: 'white',
                        width: '100%',
                        fontSize: '15px',
                        textAlign: 'center'}}
                    >
                        Let op: {data.opmerkingNL}
                    </Typography>}
                </CardContent>
                <CardActions>
                    <Grid container direction="column">
                        <Grid item>
                            <Grid container direction="row" spacing={1}>
                                <Grid item xs={6} >
                                <TextField 
                                    label="Aantal"
                                    variant="outlined" 
                                    name="aantal"
                                    value={artikel.aantal}
                                    onChange={handleChange}
                                    fullWidth={true}
                                />
                                </Grid>
                                <Grid item xs={6}>
                                    <Select
                                        id="eenheid"
                                        variant="outlined"
                                        value={artikel.eenheid < 1 ? 'x': artikel.eenheid}
                                        name="eenheid"
                                        onChange={handleChange}
                                        fullWidth={true}
                                        style={{height: '98%'}}
                                        >
                                        
                                        <MenuItem value="x" disabled>EENHEID</MenuItem>
                                        {data.voorkeurseenheid  && data.voorkeurseenheid !== data.basiseenheid && <MenuItem value={data.voorkeurseenheid}>{data.voorkeurseenheid}</MenuItem> }
                                        <MenuItem value={data.basiseenheid}>{data.basiseenheid}</MenuItem>                                        
                                    </Select>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Button variant="outlined" color="primary" style={{marginTop: '10px'}} fullWidth={true} onClick={(e) => onAdd(e, data, artikel)}>
                                Toevoegen aan bestelling
                            </Button>
                        </Grid>
                    </Grid>
                </CardActions>
            </Card>
        </Grid>
    )
}

export default Artikel
