import { Container, Grid, Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import React, { useState } from 'react'
import BarcodeScannerComponent from "react-qr-barcode-scanner";
import { getToken } from '../../Utils/Common';

const BarcodeScanner = ({onAdd}) => {
    const [ data, setData ] = useState([{message: ''}]);
    // eslint-disable-next-line
    const [lamp, setLamp ] = useState(false);
    const token = getToken();
    const QRSet = new Set();
    const succesAudio = new Audio('https://freesound.org/data/previews/391/391650_7368738-lq.mp3');
    const errorAudio = new Audio('https://freesound.org/data/previews/327/327738_3694529-lq.mp3');

    return (
        <Container>
            <Typography variant="h1" style={{textAlign: 'center'}}>Scan de QR-code op het product</Typography>
            {data[0]?.id ?
                    <Grid container direction="row" justifyContent="center">
                        <Grid item>               
                            <Alert style={{margin: 10, width: '400px'}} severity="success"> Product toegevoegd aan order</Alert>
                        </Grid>
                    </Grid>
                    :
                    <Grid container direction="row" justifyContent="center">
                        <Grid item>               
                            <Alert style={{margin: 10, width: '400px'}} severity={data[0]?.message === 'Geen geldige QR-code' ? "error" : "info"}> {data[0]?.message}</Alert>
                        </Grid>
                    </Grid>
                    
                }
            <div style={{flex: 1, justifyContent: 'center', margin: 'auto'}}>
                <BarcodeScannerComponent
                    width={'100%'}
                    height={'300'}
                    torch={lamp}
                    onUpdate={(err, result) => {
                    if (result) {
                        
                        if(!QRSet.has(result.text[0])){
                            QRSet.add(result.text[0]);
                            const baseData = result?.text.split(",");
                            const test = [{id: baseData[0], eenheid: baseData[1].trim(), aantal: baseData[2].trim()}]; 

                            const keuze = {eenheid: baseData[1].trim(), aantal: 1};

                            fetch(
                                `https://backend.blackcoffeeandsupplies.shop/articles/${baseData[0]}`,
                                {
                                    method: "GET",
                                    headers: new Headers({
                                        'Authorization': 'Bearer '+ token
                                    })
                                }
                                )
                                .then(res => res.json())
                                .then((result) => {
                                    
                                    if(result?._id?.length > 5){
                                        var event = new Event('test');
                                        onAdd(event, result, keuze);
                                        succesAudio.currentTime = 0;
                                        succesAudio.play();
                                        
                                    }else{
                                        setData([{message: 'Geen geldige QR-code'}])
                                        errorAudio.currentTime = 0;
                                        errorAudio.play();
                                    }
                                }).catch((error) => {
                                    console.log(error)
                                    
                                })

                            setData(test);
                        }
                    } else {setData([{message: 'Geen QR-code gevonden'}])};
                    }} 
                />
                
                {/* <p style={{textAlign: 'center'}}><Button variant="outlined" onClick={() => {!lamp ? setLamp(true) : setLamp(false)}} >Lamp {!lamp ? 'aan' : 'uit'}</Button></p> */}
            </div>
        </Container>
    )
}

export default BarcodeScanner
