import { AccordionSummary, Container, Typography, Accordion, AccordionDetails, makeStyles, Grid} from '@material-ui/core'
import { Alert, AlertTitle } from '@material-ui/lab';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React, { useEffect, useState } from 'react'
import { useRecoilValue } from 'recoil';
import { getUser } from '../../Utils/Common';
import { bedrijfState } from '../../Utils/Recoil/Atoms';
import OrderRegels from './OrderRegels';

const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      flexBasis: '33.33%',
      flexShrink: 0,
    },
    secondaryHeading: {
      fontSize: theme.typography.pxToRem(15),
      color: theme.palette.text.secondary,
      paddingRight: 30
    },
  }));

 

const LopendeOrders = () => {

    const [expanded, setExpanded] = useState(false);
    const [loading, setLoading] = useState(true);
    const [Orders, setOrders] = useState(['rows']);
    const bedrijf = useRecoilValue(bedrijfState);
    const klant = getUser(); 
    const bedrijfID = klant?.companies?.filter(data => data._id === bedrijf)[0].relatienummer;

    const classes = useStyles();

    useEffect(() => {
        setLoading(true);
        let mounted = true;
        
        if(bedrijfID.length > 4) {
            fetch(
                `https://82429.rest.afas.online/ProfitRestServices/connectors/API_Verkooporder?filterfieldids=status%2CdebNummer&filtervalues=Verwerkt%2C${bedrijfID}&operatortypes=7%2C1&orderbyfieldids=-bestelDatum`,
                {
                    method: "GET",
                    headers: new Headers({
                        'Authorization': process.env.REACT_APP_AFASKEY
                    }, {cache: "reload"})
                }
                )
                .then(res => res.json())
                .then(response => {
                    if(mounted) {
                        setOrders(response.rows);
                        setLoading(false);
                    }
                })
                .catch(error => console.log(error));
            }
        return () => { 
            mounted = false; 
        }

    }, [bedrijfID])

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    console.log(loading)

    if(loading){
        return (
            <Container>
                <Alert severity="info" style={{marginTop: 20}}>
                    <AlertTitle>Orders worden geladen</AlertTitle>
                    Even geduld aub, de orders worden geladen...
                </Alert>
            </Container>
        );
    }
    
    return (
        <Container>
            <Typography variant="h1" gutterBottom>Actieve Orders</Typography>
            {Orders.length < 1 && "Jullie hebben nu geen lopende orders"}
            {Orders.length > 0 &&
                Orders.map((data, i) => {
                    return(
                        <Accordion key={i} expanded={expanded === `order${i}`} onChange={handleChange(`order${i}`)}>
                            <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                            >
                            <Typography className={classes.heading} style={{textTransform: 'uppercase'}} variant="body1">Order: #{data.nummer}</Typography>
                            <Grid container direction="row" justifyContent="space-between" alignItems="top">
                                <Grid item><Typography className={classes.secondaryHeading}><strong>Besteld op:</strong> {new Date(data?.bestelDatum)?.toLocaleDateString('nl-NL')} <strong>door:</strong> {data?.besteldDoor}</Typography></Grid>
                                <Grid item><Typography className={classes.secondaryHeading}></Typography></Grid>
                                
                                <Grid Container direction="column">
                                    <Grid item>{data?.defLeverdatum ? <Typography className={classes.secondaryHeading} style={{color: 'green'}}> Definitieve leverdatum: {new Date(data?.defLeverdatum)?.toLocaleDateString('nl-NL')}</Typography> :  data.gewensteLeverdatum ? <Typography className={classes.secondaryHeading} style={{color: 'orange'}}> Aangevraagde leverdatum: {new Date(data?.gewensteLeverdatum)?.toLocaleDateString('nl-NL')} </Typography> : "Geen leverdatum opgegeven"}</Grid>
                                    <Grid item><Typography  variant="body1" color="secondary">De order is {data?.status.toLowerCase()}</Typography></Grid>
                                </Grid>
                                
                            </Grid>
                            </AccordionSummary>
                            <AccordionDetails>
                            <Grid container spacing={2} direction="column">
                                <Grid item>
                                    <Grid container spacing={2} direction="column">
                                        <Grid item>
                                            <Grid container direction="row" justifyContent="space-between">
                                                <Grid item>
                                                    <Typography variant="h6" >Opmerking:</Typography>
                                                </Grid>
                                                <Grid item>
                                                    
                                                </Grid>
                                            </Grid>
                                            
                                        </Grid>
                                        <Grid item>
                                            <Typography variant="body2">
                                                {data?.opmerking}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    <Grid container direction="row" spacing={1}>
                                        <Grid item xs={2} sm={2} md={1}><Typography variant="body1">Art. nr.</Typography></Grid>
                                        <Grid item xs={4} sm={4}  md={8}><Typography variant="body1">Omschrijving</Typography></Grid>
                                        <Grid item xs={2} sm={2}  md={1}><Typography variant="body1" style={{textAlign: 'center'}}>Eenheid</Typography></Grid>
                                        <Grid item xs={2} sm={2}  md={1}><Typography variant="body1" style={{textAlign: 'center'}}>Aantal</Typography></Grid>
                                        <Grid item xs={2} sm={2}  md={1}><Typography variant="body1" style={{textAlign: 'center'}}>Backorder</Typography></Grid>
                                    </Grid>
                                    <OrderRegels orderNo={data.nummer}/>
                                </Grid>
                            </Grid>
                            </AccordionDetails>
                        </Accordion>
                    )
                })
            }
        </Container>
    )
}

export default LopendeOrders
