import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { Button, Grid, Typography } from '@material-ui/core';
import HelpOutlineOutlinedIcon from '@material-ui/icons/HelpOutlineOutlined';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

export default function CallModal() {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button onClick={handleOpen} ><HelpOutlineOutlinedIcon /></Button>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper} style={{width: '800px'}}>
            <Grid container direction="row" justifyContent="space-between">
            <Grid item>
                <Typography variant="h5" id="transition-modal-title" gutterBottom>Wat vind ik waar op de site?</Typography>
            </Grid>
            <Grid item>
                <Button onClick={handleClose}>X</Button>
            </Grid>
            </Grid>
            
            <Typography variant="h6" id="transition-modal-title">Berichten</Typography>
              <Typography variant="body2" id="transition-modal-description" gutterBottom>
                Op de berichtenpagina vind je de meest up-to-date informatie m.b.t producten van Black. Het kan hierbij gaan om producten die nieuw, of juist uit het assortiment zijn.
              </Typography>
            <Typography variant="h6" id="transition-modal-title">Assortiment</Typography>
              <Typography variant="body2" id="transition-modal-description" gutterBottom>
                Wanneer je wilt bestellen ga je naar de pagina; 'Assortiment', hier vind je alle producten die aan jullie bedrijf gekoppeld zijn. Mis je nou producten of wil je graag wat nieuws proberen? Neem dan even contact met ons op en we zullen deze aan je omgeving toevoegen.
              </Typography>
            <Typography variant="h6" id="transition-modal-title">Actieve orders</Typography>
              <Typography variant="body2" id="transition-modal-description" gutterBottom>
                Wanneer je je order geplaatst hebt maar deze nog niet (volledig) uitgeleverd is vind je deze in het overzicht onder Actieve Orders. Je kan hier, nadat wij de order verwerkt hebben, ook de definitieve leverdatum vinden.
              </Typography>
            <Typography variant="h6" id="transition-modal-title">Huidige bestelling</Typography>
              <Typography variant="body2" id="transition-modal-description" gutterBottom>
                De pagina 'Huidige Bestelling' is als het waren je winkelmandje. Hier vind je de producten die je op de pagina assortiment hebt toegevoegd aan de order in een handig overzicht. Vanaf hier kan je een opmerking toevoegen en een gewenste leverdatum kiezen.
              </Typography>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}