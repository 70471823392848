import { atom } from 'recoil'
import { getUser } from '../Common';

const user = getUser();

export const tokenState = atom({
  key: 'tokenState', // * unique Key (with respect to other atoms/selectors)
  default: [] // * default value (aka initial value)
})

export const cartState = atom({
    key: 'cartState', // * unique Key (with respect to other atoms/selectors)
    default: [] // * default value (aka initial value)
  })

export const bedrijfState = atom({
  key: 'bedrijfState', // * unique Key (with respect to other atoms/selectors)
  default: user?.companies[0]?._id ? user?.companies[0]?._id : '' // * default value (aka initial value)
})

export const orderStatusState = atom({
  key: 'orderStatusState', // * unique Key (with respect to other atoms/selectors)
  default: null // * default value (aka initial value)
})