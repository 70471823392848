import React, { useEffect, useState } from 'react'
import { useRecoilValue } from 'recoil';
import Carousel from 'react-material-ui-carousel'
import { Paper, Button, Typography, Grid, CardMedia } from '@material-ui/core'

import { cartState, tokenState } from '../../../Utils/Recoil/Atoms';


function ItemCarousel({onAdd}) {

    const token = useRecoilValue(tokenState);
    const cartItems = useRecoilValue(cartState);

    const cartItemIds = cartItems.map(data => data._id);

    const [items, setItems] = useState([{}]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        if(token.length > 20) {
            fetch(
            `https://backend.blackcoffeeandsupplies.shop/articles?_upsellProduct=true`,
            {
                method: "GET",
                headers: new Headers({
                    'Authorization': 'Bearer '+ token
                })
            }
            )
            .then(res => res.json())
            .then(response => {
                setItems(response);
                setIsLoading(false);
            })
            .catch(error => console.log(error));
        }
        // eslint-disable-next-line
      }, [cartItems]);

    if(isLoading){
        return ("Voorgestelde items worden geladen...")
    }
    
    return (
        <>
            <Typography variant="h5" gutterBottom style={{marginTop: 20}}>Heb je hier ook al aan gedacht?</Typography>
            <Carousel interval="5000" animation="slide">
                {items.length > 0 && items.filter(items => !cartItemIds.includes(items._id)).map( (item, i) => {
                    var productImage = new Image();
                    productImage.src = `data:image/png;base64,${item.image}`;
                    const keuzeEenheid = item.voorkeurseenheid > 0 ? item.voorkeurseenheid : item.basiseenheid
                    const keuze = {eenheid: keuzeEenheid, productImage: productImage, aantal: 1}

                        return(
                            <Paper style={{padding: 10}} key={i}>
                                <Grid container spacing={2} direction="row">
                                    <Grid item xs={6} >
                                        <Grid container direction="column" justifyContent="space-between" alignItems="flex-start">
                                            <Grid item xs={12}>
                                                <Typography variant="h6">{item.omschrijving}</Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography variant="body1" gutterBottom>{item.categorie?.omschrijving}</Typography>
                                                <Typography variant="caption" gutterBottom>{item.itemcode}</Typography>
                                                <Typography variant="body2" gutterBottom>{item.opmerkingNL}</Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                            <Button className="CheckButton" variant="outlined" onClick={(e) => onAdd(e, item, keuze)}>
                                                { item.voorkeurseenheid > 0 ? '1 ' + item.voorkeurseenheid : '1 ' + item.basiseenheid + ' toevoegen aan order'}
                                            </Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={6} >
                                    <CardMedia
                                        style={{height: '250px'}}
                                        image= {productImage.src.length > 50 ? productImage.src : 'https://help4access.com/wp-content/uploads/2020/12/no-image-available.jpeg'}//{productImage.src}
                                        title={item.omschrijving}
                                    />
                                    </Grid>
                                </Grid>
                            </Paper>
                        )    
                    } ) }
            </Carousel>
        </>
    )
}

export default ItemCarousel
