import { Divider, TextField, Paper, Button, Container, Grid, Typography, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { Alert } from '@material-ui/lab';
import { useRecoilState, useRecoilValue } from "recoil";
import React, { useState } from 'react';
import LocationCityOutlinedIcon from '@material-ui/icons/LocationCityOutlined';

import { PlaceOrder } from '../../Utils/PlaceOrder';
import { getUser } from '../../Utils/Common';
import  ItemCarousel  from './Carousel/Carousel';
import { cartState, tokenState, bedrijfState } from "../../Utils/Recoil/Atoms";

import 'date-fns';

export default function Cart({onAdd, onRemove}) {

  const token = useRecoilValue(tokenState);
  const bedrijf = useRecoilValue(bedrijfState); //Global state
  const [cartItems, setCartItems] = useRecoilState(cartState);

  const [selectedDate, setSelectedDate] = useState(new Date());
  const [opmerking, setOpmerking] = useState('');
  const [orderStatus, setOrderStatus] = useState(null);
  const [loading, setLoading] = useState(false)
  
  const user = getUser();

  function disableDays(date) {

    const currentCompanie = user?.companies.filter(data => data._id === bedrijf);
    
    if (currentCompanie[0]?.Leverdag_1 !== null){
      const leverdag1 = currentCompanie[0]?.Leverdag_1;
      
      switch (leverdag1) {
        case "Zondag":
          var dagnummer1 = 0;
          break;
        case "Maandag":
          // eslint-disable-next-line
          var dagnummer1 = 1;
          break;
        case "Dinsdag":
          // eslint-disable-next-line
          var dagnummer1 = 2;
          break;
        case "Woensdag":
          // eslint-disable-next-line
          var dagnummer1 = 3;
          break;
        case "Donderdag":
          // eslint-disable-next-line
          var dagnummer1 = 4;
          break;
        case "Vrijdag":
          // eslint-disable-next-line
          var dagnummer1 = 5;
          break;
        case "Zaterdag":
          // eslint-disable-next-line
          var dagnummer1 = 6;
          break;
        default:
          // eslint-disable-next-line
          var dagnummer1 = 1
      }
    }

    if (currentCompanie[0].Leverdag_2 !== null){
      const leverdag2 = currentCompanie[0]?.Leverdag_2;
      
      switch (leverdag2) {
        case "Zondag":
          // eslint-disable-next-line
          var dagnummer2 = 0;
          break;
        case "Maandag":
          // eslint-disable-next-line
          var dagnummer2 = 1;
          break;
        case "Dinsdag":
          // eslint-disable-next-line
          var dagnummer2 = 2;
          break;
        case "Woensdag":
          // eslint-disable-next-line
          var dagnummer2 = 3;
          break;
        case "Donderdag":
          // eslint-disable-next-line
          var dagnummer2 = 4;
          break;
        case "Vrijdag":
          // eslint-disable-next-line
          var dagnummer2 = 5;
          break;
        case "Zaterdag":
          // eslint-disable-next-line
          var dagnummer2 = 6;
          break;
        default:
          // eslint-disable-next-line
          var dagnummer2 = 1
      }
    }

    const dagen = date.getDay() !== dagnummer1 & date.getDay() !== dagnummer2;

    return dagen;
  }

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  return (
    <Container>
      <Typography variant="h1" gutterBottom >Huidige Bestelling</Typography>
      {orderStatus !== null && <><Alert style={{margin: 10}} severity="info">{orderStatus}</Alert></>}
      <Grid container spacing={2}>
        <Grid item xs={12} md={8}>
          <Paper style={{padding: 10}}>
            <Grid container >
                <Grid item xs={2} md={2}><Typography variant="body1" gutterBottom></Typography></Grid>
                <Grid item xs={4} md={4}><Typography variant="body1" gutterBottom>Omschrijving</Typography></Grid>
                <Grid item xs={2} md={2}><Typography variant="body1" gutterBottom>Aantal</Typography></Grid>
                <Grid item xs={2} md={2}><Typography variant="body1" gutterBottom>Eenheid</Typography></Grid>
                <Grid item xs={2} md={2}></Grid>
              </Grid>
              <Divider style={{marginBottom: 10}}/>
              {cartItems.length === 0 && <Typography variant="body1">Je hebt geen artikelen geselecteerd.</Typography>}
            
            {cartItems.map(item => {
              var productImage = new Image();
              productImage.src = `data:image/png;base64,${item.image}`;
              const keuze = {eenheid: item.gekozenEenheid, aantal: 1}
            return (
              <Grid key={item.omschrijving} container style={{marginBottom: 10}}>
                <Grid item xs={2} md={2}><img src={productImage.src.length > 50 ? productImage.src : 'https://help4access.com/wp-content/uploads/2020/12/no-image-available.jpeg'} width="60px" alt={item.omschrijving}/></Grid>
                <Grid item xs={4} md={4}><Typography variant="body2">{item.omschrijving}</Typography></Grid>
                <Grid item xs={2} md={2}><Typography variant="body2">{item.qty}</Typography></Grid>
                <Grid item xs={2} md={2}><Typography variant="body2">{item.gekozenEenheid}</Typography></Grid>
                <Grid item xs={2} md={2}>
                  <Button style={{margin: 1}} variant="outlined" color="primary" onClick={(e) => onAdd(e, item, keuze)} className="add"> + </Button>               
                  <Button style={{margin: 1}} variant="outlined" color="secondary" onClick={(e) => onRemove(e, item)} className="remove"> - </Button>
                </Grid>
              </Grid>
            )})}
          </Paper>
          <ItemCarousel onAdd={onAdd} />
        </Grid>
        <Grid item xs={12} md={4}>
          {cartItems.length !== 0 && (
              <Paper style={{padding: 15}}>
                  <TextField id="outlined-basic" label="Opmerking" variant="outlined" value={opmerking} onChange={(e) => setOpmerking(e.target.value)} style={{width: "100%", marginBottom: 8}} />
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      margin="normal"
                      id="date-picker-dialog"
                      label="Gewenste leverdatum"
                      format="dd/MM/yyyy"
                      value={selectedDate}
                      onChange={handleDateChange}
                      style={{width: "100%"}}
                      shouldDisableDate={disableDays}
                      disablePast={true}
                      autoOk={true}
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                    />
                  </MuiPickersUtilsProvider>
                  <Typography variant="caption" color="secondary">
                    Met het kiezen van de leverdatum graag rekening houden met de vaste leverdagen:
                  </Typography>
                  <List dense={true}>
                    <ListItem>
                      <ListItemIcon>
                        <LocationCityOutlinedIcon />
                      </ListItemIcon>
                      <ListItemText
                        primary="Noord-Holland: Dinsdag & Donderdag"
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemIcon>
                        <LocationCityOutlinedIcon />
                      </ListItemIcon>
                      <ListItemText
                        primary="Zuid-Holland: Donderdag"
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemIcon>
                        <LocationCityOutlinedIcon />
                      </ListItemIcon>
                      <ListItemText
                        primary="Utrecht: Woensdag"
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemIcon>
                        <LocationCityOutlinedIcon />
                      </ListItemIcon>
                      <ListItemText
                        primary="Amersfoort e.o.: Donderdag"
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemIcon>
                        <LocationCityOutlinedIcon />
                      </ListItemIcon>
                      <ListItemText
                        primary="Oost /-en Zuid Nederland: Vrijdag"
                      />
                    </ListItem>
                  </List>
                  <Button style={{width: '100%'}} color="primary" variant="outlined" onClick={(e) => PlaceOrder(e, cartItems, bedrijf, user, selectedDate, opmerking, token, setOrderStatus, setLoading, setCartItems)}>
                    {loading ? 'Je bestelling wordt verwerkt...' : 'Bestellen'}
                  </Button>
              </Paper>
            )}
        </Grid>
      </Grid>  
    </Container>
  );
}