import { Divider, Grid, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react'

const OrderRegels = ({ orderNo }) => {

    const [orderRegels, setOrderRegels] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    
    useEffect(() => {
        let mounted = true;
        setIsLoading(true);
        if(orderNo) {
            fetch(
                `https://82429.rest.afas.online/ProfitRestServices/connectors/API_Verkooporderregels?filterfieldids=orderNummer&filtervalues=${orderNo}&operatortypes=1&orderbyfieldids=-volgNummer`,
                {
                    method: "GET",
                    headers: new Headers({
                        'Authorization': process.env.REACT_APP_AFASKEY
                    })
                }
                )
                .then(res => res.json())
                .then(response => {
                    if(mounted) {
                        setOrderRegels(response.rows);
                    }
                })
                .catch(error => console.log(error));
            }
            setIsLoading(false);
        return () => { 
            mounted = false; 
        }

    }, [orderNo])

    if(isLoading) {
        return ("Orderregels worden opgehaald...")
    }
    return (
        <>
        {orderRegels.map((regels, i) => {

            return (
                <div key={i}>
                    <Grid container direction="row" spacing={1}>
                        <Grid item xs ={2} sm={2} md={1}><Typography variant="body2">{regels.itemCode}</Typography></Grid>
                        <Grid item xs ={4} sm={4} md={8}><Typography variant="body2">{regels.artikelOmschrijving}</Typography></Grid>
                        <Grid item xs ={2} sm={2} md={1}><Typography variant="body2" style={{textAlign: 'center'}}>{regels.eenheid}</Typography></Grid>
                        <Grid item xs ={2} sm={2} md={1}><Typography variant="body2" style={{textAlign: 'center'}}>{regels.aantal}</Typography></Grid>
                        <Grid item xs ={2} sm={2} md={1}><Typography variant="body2" style={{textAlign: 'center'}} color={regels.aantalWachtorder > 0 ? 'secondary' : 'default'}>{regels.aantalWachtorder}</Typography></Grid>
                    </Grid>
                    <Divider />
                </div>
            )
        })}
        </>
    )
}

export default OrderRegels
